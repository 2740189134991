import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import appSlice from "./slices/appSlice";
import notificationSlice from "./slices/notificationSlice";
import userSlice from "./slices/userSlice";
import authSlice from "./slices/authSlice";
import formSlice from "./slices/formSlice";
import postsSlice from "./slices/postsSlice";

export const store = configureStore({
  reducer: {
    app: appSlice,
    notification: notificationSlice,
    user: userSlice,
    auth: authSlice,
    form: formSlice,
    post: postsSlice,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

setupListeners(store.dispatch);
