import { forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import {
  setDialog,
  resetForm,
  User,
  setResetPasswordByAdminDialog,
} from "../../../app/slices/userSlice";
import { Controller, useForm } from "react-hook-form";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UsersForm = () => {
  const _User = useSelector((state) => state.user.user);
  const _SearchBody = useSelector((state) => state.user.searchBody);
  const _PageNo = useSelector((state) => state.user.pageNo);

  const isAdd = _User.form.id ? false : true;
  const { control, reset, watch, register, handleSubmit, setValue } = useForm({
    defaultValues: _User.form,
  });

  const onSubmit = (data) => {
    if (isAdd) {
      dispatch(
        User.create(
          {
            fullName: data.fullName,
            username: data.username,
            password: data.password,
          },
          _PageNo,
          _SearchBody
        )
      );
    } else {
      dispatch(
        User.update(
          data.id,
          {
            fullName: data.fullName,
            username: data.username,
            password: data.password,
          },
          _PageNo,
          _SearchBody
        )
      );
    }
  };
  useEffect(() => {
    reset({ ..._User.form });
  }, [_User.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, []);

  const dispatch = useDispatch();

  const handlDialog = () => {
    dispatch(setDialog());
  };

  return (
    <Dialog
      open={_User.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      onClose={() => {
        handlDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"secondary"}
          gutterBottom
          component="div"
        >
          {isAdd ? "Add " : "Update "}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="fullName"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="Full Name"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="username"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label="Username"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            {isAdd && (
              <Grid item xs={12}>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      required
                      size="small"
                      label="Password"
                      type="password"
                      fullWidth
                      {...field}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <Button type="submit" variant="contained" color="primary">
                Save
              </Button>
            </Grid>
            {!isAdd && (
              <Grid
                item
                xs={12}
                md={6}
                sx={{ display: "flex", justifyContent: "end" }}
              >
                <Button
                  onClick={() =>
                    dispatch(setResetPasswordByAdminDialog(_User.form.id))
                  }
                  variant="contained"
                  color="primary"
                >
                  Reset Password
                </Button>
              </Grid>
            )}
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default UsersForm;
