import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
const formApi = factory.get("form");
const initialState = {
  pageNo: 1,
  searchBody: {},
  form: {
    loading: false,
    dialog: false,
    form: {},
  },
  forms: {
    loading: false,
    data: [],
    total: 0,
  },
};

export const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.form.dialog = !state.form.dialog;
    },
    setDataTable: (state, { payload }) => {
      state.forms.data = payload.data.data.values;
      state.forms.total = payload.data.data.totalCount;
    },
    setById: (state, { payload }) => {
      state.form.form = payload;
    },
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    resetForm: (state, action) => {
      state.form.form = initialState.form.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
    setPageNo: (state, { payload }) => {
      state.pageNo = payload;
    },
  },
});

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
  setPageNo,
} = formSlice.actions;

export default formSlice.reducer;

//axios
const getAll = (pageNo, searchBody) => async (dispatch) => {
  try {
    dispatch(setLoading("form"));
    const res = await formApi.getAll(pageNo, searchBody);
    dispatch(setDataTable(res));
    dispatch(setLoading("form"));
  } catch (err) {
    dispatch(setLoading("form"));
    dispatch(
      showNotification({
        message: err.response.data.message,
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deleteById = (id, pageNo, _SearchBody) => async (dispatch) => {
  try {
    await formApi.delete(id);
    dispatch(
      showNotification({
        message: "Form Deleted Successfully",
        type: "success",
      })
    );
    dispatch(getAll(pageNo, _SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.message,
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("form"));
    const res = await formApi.getById(id);
    dispatch(setById(res.data.data));

    dispatch(setDialog());
    dispatch(setLoading("form"));
  } catch (err) {
    dispatch(setLoading("form"));
    dispatch(
      showNotification({
        message: err.response.data.message,
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const create = (data) => async (dispatch) => {
  try {
    await formApi.create(data);
    dispatch(
      showNotification({
        message: "Form Created Successfully",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll());
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.message,
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const update = (id, data, _SearchBody) => async (dispatch) => {
  try {
    await formApi.update(id, data);
    dispatch(
      showNotification({
        message: "Form Updated Successfully",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.message,
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const Forms = {
  getAll,
  deleteById,
  getById,
  create,
  update,
};
