import { Grid } from "@mui/material";
import React from "react";
import ApplicantsHeader from "./home/header";
import FormTable from "./home/table";

const Form = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ApplicantsHeader />
      </Grid>
      <Grid item xs={12}>
        <FormTable />
      </Grid>
    </Grid>
  );
};

export default Form;
