import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAll(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/user/${pageNo}?${params}`);
  },
  login(data) {
    return api.post(`/api/login`, data);
  },
  create(data) {
    return api.post(`/api/user`, data);
  },
  logout() {
    return api.post(`/api/logout`);
  },
  getById(id) {
    return api.get(`/api/user/byid/${id}`);
  },
  update(id, data) {
    return api.put(`/api/user/${id}`, data);
  },
  changeStatus(data) {
    return api.put(`/api/user/status`, data);
  },
  changePassword(data) {
    return api.put(`/api/user/password`, data);
  },
  loginHistory(userId) {
    return api.get(`/api/user/loghistory/${userId}`);
  },
  resetPassword(id, data) {
    return api.put(`/api/user/password/${id}`, data);
  },
  resetPasswordByUser(data) {
    return api.put(`/api/user/password/`, data);
  },
  userInfo() {
    return api.get(`/api/user/userinfo`);
  },
  delete(id) {
    return api.delete(`/api/user/${id}`);
  },
};
