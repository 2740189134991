import { useEffect } from "react";
import { useSelector } from "react-redux";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import dayjs from "dayjs";

const PreviewPostData = () => {
  const previewData = JSON.parse(localStorage.getItem("postPreview"));
  const _Post = useSelector((state) => state.post.post);

  useEffect(() => {
    const inputDateString = dayjs().format("YYYY-MM-DD");
    const date = new Date(inputDateString);
    function formatDate(date) {
      const options = {
        year: "numeric",
        month: "long",
        day: "2-digit",
      };

      return date.toLocaleDateString(undefined, options);
    }
    const formattedDate = formatDate(date);

    const postDetailsElement = document.getElementById("postDetails");

    postDetailsElement.innerHTML = `
                  <div class="col-12">
                  <div class="title">${previewData.title}</div>
                </div>
                <div class="col-12 title-content">
                  <h4>Published on: <span style=\"color: #41AED5;">${formattedDate}</span></h4>
                  <h4>Estimated time to read: <span style=\"color: #41AED5;">
                  ${previewData.estimatedTimeToRead}
                  </span></h4>
                  ${
                    previewData.writtenBy
                      ? previewData.writtenByUrl
                        ? `<h4>Written by: <a target="_blank" style=\"text-decoration: underline;" href="${previewData.writtenByUrl}">${previewData.writtenBy}</a></h4>`
                        : `<h4>Written by: ${previewData.writtenBy}</h4>`
                      : ""
                  }
                </div>
                <div class="col-12"  style=\"display: flex; justify-content: center;">
                  <img src="${previewData.attachmentUrl}" alt="" class="" />
                </div>
                <div class="col-12">
                    ${previewData.description}
                </div>
              `;
  }, []);

  return (
    <section className="post-details">
      <div className="container">
        <div id="postDetails"></div>
      </div>
    </section>
  );
};

export default PreviewPostData;
