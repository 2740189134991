import user from "./endpoints/user";
import auth from "./endpoints/auth";
import form from "./endpoints/form";
import post from "./endpoints/posts";
import attachment from "./endpoints/attachment";

const repositories = {
  user,
  auth,
  form,
  post,
  attachment,
};

export const factory = {
  get: (name) => repositories[name],
};
