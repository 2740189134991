import {
  createSlice
} from "@reduxjs/toolkit";
import {
  showNotification
} from "./notificationSlice";
import {
  factory
} from "../../api/apiFactory";
const authApi = factory.get("auth");
const initialState = {
  loading: false
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = !state.loading;
    },
  }
})

export const {
  setLoading,
} = authSlice.actions

export default authSlice.reducer;

//axios
const login = (data) => async (dispatch) => {
  try {
    dispatch(setLoading('auth'));
    const res = await authApi.login(data)
    dispatch(setLoading('auth'));
    return Promise.resolve(res)
  } catch (err) {
    dispatch(setLoading('auth'));
    dispatch(showNotification({
      message: err.response.data.message,
      type: "error"
    }));
    throw new Error(err);
  }
};
const getIp = (data) => async (dispatch) => {
  try {
    const res = await authApi.getIp(data)
    return Promise.resolve(res)
  } catch (err) {
    throw new Error(err);
  }
};
export const Auth = {
  login,
  getIp
}