import {
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Autocomplete,
  Tooltip,
  Typography,
  Link,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { BiTrash, BiEdit } from "react-icons/bi";
import { AiFillEye } from "react-icons/ai";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Posts,
  setPreviewData,
  setSearchBody,
} from "../../../../app/slices/postsSlice";
import ConfirmationDialog from "../../../../helpers/ConfirmationDialog";
import { useNavigate } from "react-router";

const PostsTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const _Posts = useSelector((state) => state.post.posts);
  const loading = useSelector((state) => state.post.post.loading);
  const _SearchBody = useSelector((state) => state.post.searchBody);
  const _PageNo = useSelector((state) => state.post.pageNo);

  const [currentPage, setCurrentPage] = useState(0);
  const [page, setPage] = useState(15);
  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const type = [
    { name: "Stories", key: "stories" },
    { name: "News", key: "news" },
    { name: "Resources", key: "resources" },
  ];

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(Posts.deleteById(dialogData.id, _PageNo, _SearchBody));
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  function extractTextBetweenTags(html) {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    const text = tempElement.textContent || tempElement.innerText;

    return text;
  }

  const columns = [
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      renderCell: (params) => extractTextBetweenTags(params.row.title),
    },
    {
      field: "type",
      headerName: "Type",
      flex: 0.5,
    },
    {
      field: "estimatedTimeToRead",
      headerName: "Estimated Time To Read",
      flex: 1,
    },
    {
      field: "writtenBy",
      headerName: "Written By",
      flex: 1,
      renderCell: (params) =>
        params.row?.writtenByUrl ? (
          <Link
            target="_blank"
            color="secondary"
            href={params.row?.writtenByUrl}
          >
            {params.row?.writtenBy}
          </Link>
        ) : (
          <> {params.row?.writtenBy}</>
        ),
    },
    {
      field: "description",
      headerName: "Description",
      flex: 2,
      renderCell: (params) => {
        return (
          <Tooltip title={extractTextBetweenTags(params.row.description)}>
            <Typography noWrap={true}>
              {extractTextBetweenTags(params.row.description)}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "AttachmentUrl",
      headerName: "Attachment",
      flex: 1,
      renderCell: (params) => (
        <Box
          component="img"
          src={params.row.attachmentUrl}
          sx={{ width: 150 }}
        />
      ),
    },
    {
      field: "operations",
      headerName: "Operations",
      flex: 0.8,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              color="warning"
              onClick={() => {
                dispatch(setPreviewData(params.row));
                window.open("/preview_post", "_blank", "noreferrer");
              }}
            >
              <AiFillEye />
            </IconButton>
            <IconButton
              color="primary"
              onClick={() => dispatch(Posts.getById(params.row.id))}
            >
              <BiEdit />
            </IconButton>
            <IconButton
              color="error"
              onClick={() => handleDeleteClick(params.row)}
            >
              <BiTrash />
            </IconButton>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(Posts.getAll(_PageNo, _SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [_SearchBody]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        pageSize: page,
        type: selectedType ?? "",
      })
    );
  }, [currentPage, page, selectedType]);

  // useEffect(() => {
  //   localStorage.removeItem("postPreview");
  // }, []);

  return (
    <Card>
      <CardContent>
        <Box>
          <ConfirmationDialog
            open={openConfirm}
            title="Confirmation"
            message="Are you sure you want to delete"
            name={
              dialogData.title ? extractTextBetweenTags(dialogData.title) : ""
            }
            confirmText="Delete"
            cancelText="Cancel"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={{}}>
          <Grid item xs={2}>
            <Autocomplete
              options={type}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.key === value.key}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label="Select Type"
                  size="small"
                />
              )}
              onChange={(e, newValue) => {
                setSelectedType(newValue ? newValue.key : null);
              }}
              value={
                type.filter((type) => type.key === selectedType)[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              <DataGrid
                rows={_Posts.data}
                columns={columns}
                pageSize={page}
                rowCount={_Posts.total}
                loading={loading}
                rowHeight={100}
                pagination
                paginationMode="server"
                selectionModel={[]}
                onPageChange={(newPage) => {
                  setCurrentPage(newPage);
                }}
                rowsPerPageOptions={[15, 25, 50, 100]}
                onPageSizeChange={(newPageSize) => {
                  setPage(newPageSize);
                }}
                components={{
                  LoadingOverlay: LinearProgress,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PostsTable;
