import {
  Grid,
  IconButton,
  LinearProgress,
  Card,
  CardContent,
  Tooltip,
  Typography,
  Button,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { BiTrash, BiSpreadsheet } from "react-icons/bi";
import { AiFillEye, AiOutlineFilePdf } from "react-icons/ai";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Forms, setSearchBody } from "../../../../app/slices/formSlice";
import ConfirmationDialog from "../../../../helpers/ConfirmationDialog";
import PostForm from "../../form";

const FormTable = () => {
  const dispatch = useDispatch();

  const _FormDialog = useSelector((state) => state.form.form.dialog);
  const _Forms = useSelector((state) => state.form.forms);
  const loading = useSelector((state) => state.form.form.loading);
  const _SearchBody = useSelector((state) => state.form.searchBody);
  const _PageNo = useSelector((state) => state.user.pageNo);

  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [page, setPage] = useState(15);
  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(Forms.deleteById(dialogData.id, _PageNo, _SearchBody));
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const columns = [
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1.2,
      renderCell: (params) => (
        <Tooltip title={params.row.email}>
          <Typography>{params.row.email}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "company",
      headerName: "Company",
      flex: 1,
    },
    {
      field: "country",
      headerName: "Country",
      flex: 1,
    },
    {
      field: "position",
      headerName: "Position",
      flex: 1,
    },
    {
      field: "queryType",
      headerName: "Query Type",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.row.queryType}>
          <Typography noWrap={true}>{params.row.queryType}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "attachmentUrl",
      headerName: "Attachment",
      flex: 1,
      renderCell: (params) =>
        params.row.attachmentUrl ? (
          <Button
            variant="contained"
            color="primary"
            size="medium"
            startIcon={<AiOutlineFilePdf />}
            href={params.row.attachmentUrl}
          >
            PDf File
          </Button>
        ) : null,
    },
    {
      field: "queryTypeOther",
      headerName: "Query Type Other",
      flex: 1,
    },
    {
      field: "message",
      headerName: "Message",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.row.message}>
          <Typography noWrap={true}>{params.row.message}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "operations",
      headerName: "Operations",
      flex: 0.8,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              color="primary"
              onClick={() => dispatch(Forms.getById(params.row.id))}
            >
              <AiFillEye />
            </IconButton>
            {/* <IconButton
              color="error"
              onClick={() => handleDeleteClick(params.row)}
            >
              <BiTrash />
            </IconButton> */}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(Forms.getAll(_PageNo, _SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [_SearchBody]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        pageSize: page,
        // search: search.name,
      })
    );
  }, [currentPage, page, search]);

  return (
    <Card>
      <CardContent>
        <Box>
          {_FormDialog && <PostForm />}
          <ConfirmationDialog
            open={openConfirm}
            title="Confirmation"
            message="Are you sure you want to delete"
            name={dialogData.name}
            confirmText="Delete"
            cancelText="Cancel"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={{}}>
          {/* <Grid item xs={2}>
            <TextField
              size="small"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              label="Search Name"
              fullWidth
            />
          </Grid> */}
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              <DataGrid
                rows={_Forms.data}
                columns={columns}
                pageSize={page}
                rowCount={_Forms.total}
                loading={loading}
                rowHeight={100}
                pagination
                paginationMode="server"
                selectionModel={[]}
                onPageChange={(newPage) => {
                  setCurrentPage(newPage);
                }}
                rowsPerPageOptions={[15, 25, 50, 100]}
                onPageSizeChange={(newPageSize) => {
                  setPage(newPageSize);
                }}
                components={{
                  LoadingOverlay: LinearProgress,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default FormTable;
