import { useState } from "react";
import {
  Box,
  useMediaQuery,
  Drawer,
  Skeleton,
  Stack,
  Divider,
} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { BrowserView, MobileView } from "react-device-detect";
import PerfectScrollbar from "react-perfect-scrollbar";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { AiOutlineForm, AiOutlineHome, AiOutlineIdcard } from "react-icons/ai";
import { setDrawer } from "../../../app/slices/appSlice";
import { FiUsers } from "react-icons/fi";
import { BsFilePost, BsFillFlagFill, BsGearFill } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import { FaDesktop, FaHotel } from "react-icons/fa";
import { MdLocationOn, MdCardTravel, MdOutlinePayments } from "react-icons/md";

const SideBar = () => {
  //uses
  const theme = useTheme();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //constants
  const drawerWidth = 220;

  //states
  const [open, setOpen] = useState(false);
  const [selectedID, setSelectedID] = useState(null);

  //selectors and media queries
  const drawerOpen = useSelector((state) => state.app.is_drawer_opened);
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));

  //functions
  const links = [
    {
      id: 1,
      name: "Users",
      icon: <FiUsers size={18} />,
      path: "/users",
      sub: [],
    },
    {
      id: 2,
      name: "Applicants",
      icon: <AiOutlineIdcard size={18} />,
      path: "/applicants",
      sub: [],
    },
    {
      id: 3,
      name: "Posts",
      icon: <BsFilePost size={18} />,
      path: "/posts",
      sub: [],
    },
  ];
  const drawerSubLinks = (link, index) => {
    return link.sub.length > 0 ? (
      <Collapse in={open && index === selectedID} timeout="auto" unmountOnExit>
        <List dense={true} component="div" disablePadding>
          {link.sub.map((item, index) => (
            <ListItemButton
              key={index}
              sx={{
                pl: 4,
                marginTop: 0.5,
                backgroundColor:
                  "/" + item.path === pathname
                    ? theme.palette.primary.light
                    : "transparent",
                color: theme.palette.primary.main,
                borderRadius: "8px",
              }}
              onClick={() => {
                navigate(item.path);
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: "30px",
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    ) : null;
  };
  const drawerLinks = (
    <div>
      {/* <Toolbar /> */}
      <Box display="flex" justifyContent="center" margin={1}>
        {/* <img src={logo_light} width={50} height={50} /> */}
      </Box>
      {/* <Divider /> */}
      <List
        dense={false}
        sx={{
          "& .MuiListItem-root:hover": {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.main,
            borderRadius: "8px",
          },
          "& .Mui-selected": {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.main,
            borderRadius: "8px",
            fontWeight: "bold",
          },
        }}
      >
        {links.map((item, index) => (
          <div key={index}>
            <ListItem key={index} disablePadding>
              <ListItemButton
                key={index}
                // selected={index === selectedID}
                selected={item.path === "/" + pathname.split("/")[1]}
                onClick={() => {
                  if (item.sub.length == 0) navigate(item.path);
                  if (index === selectedID) {
                    setOpen(!open);
                  } else {
                    setOpen(true);
                    setSelectedID(index);
                  }
                }}
              >
                <ListItemIcon
                  sx={{
                    // color: "white",
                    minWidth: "30px",
                    // color: item.path === location.pathname ? "primary.main" : "",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  sx={{
                    fontSize: "0.8rem",
                  }}
                  disableTypography={true}
                  primary={item.name}
                />
                {item.sub.length > 0 ? (
                  open && index === selectedID ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )
                ) : (
                  ""
                )}
              </ListItemButton>
            </ListItem>
            {drawerSubLinks(item, index)}
          </div>
        ))}
      </List>
    </div>
  );
  const drawerViewAndScroll = (
    <>
      {/* sx={{ display: { xs: "block", md: "none" } }} */}
      <Box>
        <Box
          sx={{
            display: "flex",
            mx: "auto",
            justifyContent: "center",
            height: "70px",
            padding: 2,
          }}
        >
          {/* <LogoSection /> */}
          <img
            alt="ivp.png"
            src={require("../../../assets/images/ivp.png")}
            height={50}
          />
        </Box>
        <Divider sx={{paddingTop: 1}} />
      </Box>
      <Box>
        <Box>
          <BrowserView>
            <PerfectScrollbar
              component="div"
              style={{
                height: !matchUpMd
                  ? "calc(100vh - 56px)"
                  : "calc(100vh - 88px)",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              {drawerLinks}

              {/* <MenuList />
                          <MenuCard /> */}
            </PerfectScrollbar>
          </BrowserView>
          <MobileView>
            <Box sx={{ px: 2 }}>{drawerLinks}</Box>
          </MobileView>
        </Box>
      </Box>
    </>
  );

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : "auto" }}
      aria-label="sidebar"
    >
      <Drawer
        // container={container}
        variant={matchUpMd ? "persistent" : "temporary"}
        anchor="left"
        open={drawerOpen}
        onClose={() => dispatch(setDrawer())}
        sx={{
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            // background: theme.palette.primary.main,
            // background: "gray",
            // color: "white",
            borderRight: "none",

            // [theme.breakpoints.up("md")]: {
            //   top: "88px",
            // },
          },
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawerViewAndScroll}
      </Drawer>
    </Box>
  );
};

export default SideBar;
