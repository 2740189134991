import { Button, Card, CardHeader, Typography } from "@mui/material";
import React from "react";
import { BsFilePost } from "react-icons/bs";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { resetForm, setDialog } from "../../../../app/slices/postsSlice";
import PostForm from "../../form";

const PostsHeader = () => {
  const dispatch = useDispatch();
  const _Post = useSelector((state) => state.post.post.dialog);
  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            Posts
          </Typography>
        }
        avatar={<BsFilePost size={20} />}
        action={
          <Button
            variant="contained"
            color="primary"
            size="medium"
            startIcon={<IoIosAddCircleOutline />}
            onClick={() => {
              dispatch(setDialog());
              dispatch(resetForm());
            }}
          >
            Add New Post
          </Button>
        }
      />
      {_Post && <PostForm />}
    </Card>
  );
};

export default PostsHeader;
