import axios from "axios";
import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login(data) {
    return api.post(`/api/Auth/Login`, data);
  },
  getIp() {
    return axios.get(`https://api.hostip.info/get_html.php`);
  },
} 