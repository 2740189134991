import { Card, CardHeader, Typography, Button } from "@mui/material";
import React from "react";
import { setDialog } from "../../../../app/slices/userSlice";
import { useDispatch } from "react-redux";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import UsersForm from "../../form";
import { FiUsers } from "react-icons/fi";

const UsersHeader = () => {
  const _User = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            Users
          </Typography>
        }
        avatar={<FiUsers size={20} />}
        action={
          <Button
            variant="contained"
            color="primary"
            size="medium"
            startIcon={<IoIosAddCircleOutline />}
            onClick={() => dispatch(setDialog())}
          >
            Add new user
          </Button>
        }
      />
      {_User.dialog ? <UsersForm /> : null}
    </Card>
  );
};

export default UsersHeader;
