import { Grid } from "@mui/material";
import React from "react";
import UsersHeader from "./home/header";
import UsersTable from "./home/table";

const Users = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <UsersHeader />
      </Grid>
      <Grid item xs={12}>
        <UsersTable />
      </Grid>
    </Grid>
  );
};

export default Users;
