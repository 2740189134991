import {
  Grid,
  IconButton,
  LinearProgress,
  Card,
  CardContent,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { BiEdit, BiTrash } from "react-icons/bi";
import { useEffect, useState } from "react";
import { User, setPageNo } from "../../../../app/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { setSearchBody } from "../../../../app/slices/userSlice";
import ResetPasswordByAdminForm from "../../resetPasswordByAdmin";
import ConfirmationDialog from "../../../../helpers/ConfirmationDialog";
import ResetPasswordByUserForm from "../../resetPasswordByUser";

const UsersTable = () => {
  const dispatch = useDispatch();

  const _Users = useSelector((state) => state.user.users);
  const _SearchBody = useSelector((state) => state.user.searchBody);
  const _PageNo = useSelector((state) => state.user.pageNo);
  const loading = useSelector((state) => state.user.loading);
  const _ResetPassword = useSelector(
    (state) => state.user.resetPasswordByAdmin.dialog
  );

  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);
  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(User.deleteById(dialogData.id, _PageNo, _SearchBody));
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const columns = [
    {
      field: "fullName",
      headerName: "Full Name",
      flex: 1,
    },
    {
      field: "username",
      headerName: "User Name",
      flex: 1,
    },
    {
      field: "operations",
      headerName: "Operations",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <IconButton
              variant="contained"
              color="primary"
              onClick={() => dispatch(User.getById(params.row.id))}
            >
              <BiEdit />
            </IconButton>
            <IconButton
              color="error"
              onClick={() => handleDeleteClick(params.row)}
            >
              <BiTrash />
            </IconButton>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(User.getAll(_PageNo, _SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [_SearchBody]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        pageSize: pageSize,
      })
    );
    dispatch(setPageNo(currentPage));
  }, [currentPage, pageSize]);

  return (
    <Card>
      <CardContent>
        {_ResetPassword && <ResetPasswordByAdminForm />}
        <Box>
          <ConfirmationDialog
            open={openConfirm}
            title="Confirmation"
            message="Are you sure you want to delete"
            name={dialogData.fullName}
            confirmText="Delete"
            cancelText="Cancel"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={2}>
          {/* <Grid item xs={2}>
            <TextField
              size="small"
              onChange={(e) => {
                setSearch({
                  ...search,
                  firstName: e.target.value,
                });
              }}
              value={search.firstName}
              label="Search Name"
              fullWidth
            />
          </Grid> */}
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              <DataGrid
                rows={_Users.data}
                columns={columns}
                pageSize={_Users.pageSize}
                rowCount={_Users.total}
                loading={loading}
                rowHeight={65}
                pagination
                paginationMode="server"
                selectionModel={[]}
                onPageChange={(newPage) => setCurrentPage(newPage + 1)}
                rowsPerPageOptions={[15, 25, 50, 100]}
                onPageSizeChange={(newPageSize) => {
                  setPageSize(newPageSize);
                }}
                components={{
                  LoadingOverlay: LinearProgress,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default UsersTable;
