// import { useSelector } from 'react-redux';

import { ThemeProvider ,createTheme } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { arEG } from "@mui/material/locale";
// import rtlPlugin from "stylis-plugin-rtl";
// import { prefixer } from "stylis";
import "./App.css";
import dayjs from "./helpers/dayjs";
import NotificationSnackbar from "../src/components/Notification";

// routing
import Routes from "./routes";

// defaultTheme
import themes from "./themes";

// project imports
import NavigationScroll from "./layout/NavigationScroll";
import themeTypography from "./themes/typography";
import mycolors from "./assets/scss/_themes-vars.module.scss";
import themePalette from "./themes/palette";

// ==============================|| APP ||============================== //

const App = () => {
  const border_shadow = {
    borderRadius: 10,
    boxShadow:
      "rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px",
  };
  const theme = createTheme(
    {
      components: {
        MuiTypography: {
          defaultProps: {
            fontFamily: "Cairo"
          }
        },
        // Name of the component
        MuiCard: {
          styleOverrides: {
            root: {
              borderRadius: border_shadow.borderRadius,
              boxShadow: border_shadow.boxShadow,
            },
          },
        },
        MuiDataGrid: {
          styleOverrides: {
            root: {
              borderRadius: border_shadow.borderRadius,
              boxShadow: border_shadow.boxShadow,
            },
          },
        },
        // MuiListItemText: {
        //   styleOverrides: {
        //     primary: {
        //       // color: 'b'
        //     },
        //     "&:hover": {
        //       backgroundColor: "blue",
        //       color: "green"
        //     }
        //   }
        // },
      },
      palette: {
        type: "light",
        primary: {
          main: "#009BD0",
          contrastText: "#ffffff",
          light: "#ebe7e4",
        },
        secondary: {
          main: "#5e00d7",
          contrastText: "#ffffff",
        },
        third: {
          main: "#646973",
          contrastText: "#ffffff",
        },
      },
      typography: themeTypography,
      // direction: "rtl",
    },
    // arEG
  );
  // const cacheRtl = createCache({
  //   key: "muirtl",
  //   stylisPlugins: [prefixer, rtlPlugin],
  // });

  return (
    <StyledEngineProvider injectFirst>
      {/* <CacheProvider > */}
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <NotificationSnackbar />
          <NavigationScroll>
            <Routes />
          </NavigationScroll>
        </ThemeProvider>
      {/* </CacheProvider> */}
    </StyledEngineProvider>
  );
};

export default App;
