import { Card, CardHeader, Typography } from "@mui/material";
import React from "react";
import { AiOutlineIdcard } from "react-icons/ai";

const ApplicantsHeader = () => {
  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            Applicants
          </Typography>
        }
        avatar={<AiOutlineIdcard size={20} />}
      />
    </Card>
  );
};

export default ApplicantsHeader;
