import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType
);

const FilePondInput = ({ field, form, required, onChange }) => {
  const { name, onBlur } = field;

  return (
    <FilePond
      required={required ? true : false}
      name={name}
      file={field.value}
      credits={false}
      labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse *</span>'
      onupdatefiles={onChange}
      onBlur={onBlur}
    />
  );
};

export default FilePondInput;
