import React, { forwardRef } from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  Typography,
  Stack,
  Divider,
  Box,
  Button,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { setDialog } from "../../../app/slices/formSlice";
import { AiOutlineFilePdf } from "react-icons/ai";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PostForm = () => {
  const _Form = useSelector((state) => state.form.form);

  const dispatch = useDispatch();

  const handleDialog = () => {
    dispatch(setDialog());
  };

  const formattedText = _Form.form.message?.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));

  return (
    <Dialog
      open={_Form.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {"Form Details"}
        </Typography>
        <Divider />
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Typography sx={{ fontSize: 18 }}>First Name:</Typography>
              <Stack direction="row">
                <Typography variant="h4">{_Form.form.firstName}</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Typography sx={{ fontSize: 18 }}>Last Name:</Typography>
              <Stack direction="row">
                <Typography variant="h4">{_Form.form.lastName}</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Typography sx={{ fontSize: 18 }}>Company:</Typography>
              <Stack direction="row">
                <Typography variant="h4">{_Form.form.company}</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Typography sx={{ fontSize: 18 }}>Position:</Typography>
              <Stack direction="row">
                <Typography variant="h4">{_Form.form.position}</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Typography sx={{ fontSize: 18 }}>Email:</Typography>
              <Stack direction="row">
                <Typography variant="h4">{_Form.form.email}</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Typography sx={{ fontSize: 18 }}>Website:</Typography>
              <Stack direction="row">
                <Typography variant="h4">{_Form.form.website}</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Typography sx={{ fontSize: 18 }}>Country:</Typography>
              <Stack direction="row">
                <Typography variant="h4">{_Form.form.country}</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Typography sx={{ fontSize: 18 }}>Query Type:</Typography>
              <Stack direction="row">
                <Typography variant="h4">{_Form.form.queryType}</Typography>
              </Stack>
            </Stack>
          </Grid>
          {_Form.form.attachmentUrl && (
            <Grid item xs={12} md={6}>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                startIcon={<AiOutlineFilePdf />}
                href={_Form.form.attachmentUrl}
              >
                PDf File
              </Button>
            </Grid>
          )}
          {_Form.form.queryTypeOther && (
            <Grid item xs={12} md={6}>
              <Stack direction="row" spacing={0.5} alignItems="center">
                <Typography sx={{ fontSize: 18 }}>Query Type Other:</Typography>
                <Stack direction="row">
                  <Typography variant="h4">
                    {_Form.form.queryTypeOther}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <Stack direction="row" spacing={0.5} alignItems="start">
              <Typography sx={{ fontSize: 18 }}>Message:</Typography>
              <Stack direction="row">
                <Typography sx={{ fontSize: 18, }}>
                  {formattedText}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={12}>
            <Divider />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PostForm;
