import dayjs from "dayjs";
import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
const userApi = factory.get("user");

const initialState = {
  searchBody: {},
  pageNo: 1,
  loading: false,
  user: {
    dialog: false,
    form: {
      id: null,
      fullName: "",
      username: "",
      password: "",
    },
  },
  users: {
    data: [],
    total: 0,
    pageSize: 15,
  },
  resetPasswordByAdmin: {
    dialog: false,
    user_id: null,
    form: {
      password: "",
      new_password: "",
    },
  },
  loginHistory: {
    dialog: false,
    data: [],
  },
  userInfo: {},
  userInfoLoading: false,
  resetPasswordByUser: {
    dialog: false,
    form: {
      password: "",
      new_password: "",
    },
  },
};

export const usersSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.user.dialog = !state.user.dialog;
    },
    setResetPasswordByAdminDialog: (state, { payload }) => {
      state.resetPasswordByAdmin.dialog = !state.resetPasswordByAdmin.dialog;
      state.resetPasswordByAdmin.user_id = payload;
    },
    setResetPasswordDialog: (state, { payload }) => {
      state.resetPasswordByUser.dialog = !state.resetPasswordByUser.dialog;
    },
    setLoading: (state, action) => {
      state.loading = !state.loading;
    },
    setDataTable: (state, { payload }) => {
      state.users.data = payload.data.data.values;
      state.users.total = payload.data.data.totalCount;
      state.users.pageSize = payload.data.data.pageSize;
    },
    setById: (state, { payload }) => {
      state.user.form.id = payload.id;
      state.user.form.fullName = payload.fullName;
      state.user.form.username = payload.username;
      state.user.form.password = payload.password;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
    setPageNo: (state, { payload }) => {
      state.pageNo = payload;
    },
    resetForm: (state, action) => {
      state.user.form = initialState.user.form;
    },
    setLoginHistoryDialog: (state, { payload }) => {
      state.loginHistory.dialog = !state.loginHistory.dialog;
    },
    setLoginHistory: (state, { payload }) => {
      state.loginHistory.data = payload.data.data;
    },
    setUserInfo: (state, { payload }) => {
      state.userInfo = payload.data.data;
    },
    setUserInfoLoading: (state, action) => {
      state.userInfoLoading = !state.userInfoLoading;
    },
  },
});

export const {
  setDialog,
  setLoading,
  setDataTable,
  setById,
  setErrors,
  setUser,
  resetForm,
  resetPasswordForm,
  setSearchBody,
  setPageNo,
  setLoginHistoryDialog,
  setLoginHistory,
  setResetPasswordByAdminDialog,
  setUserInfo,
  setUserInfoLoading,
  setResetPasswordDialog,
} = usersSlice.actions;

export default usersSlice.reducer;

//axios
const getAll = (pageNo, data) => async (dispatch) => {
  try {
    dispatch(setLoading("user"));
    const res = await userApi.getAll(pageNo, data);
    dispatch(setDataTable(res));
    dispatch(setLoading("user"));
  } catch (err) {
    dispatch(setLoading("user"));
    throw new Error(err);
  }
};
const create = (data, pageNo, searchBody) => async (dispatch) => {
  try {
    await userApi.create(data);
    dispatch(
      showNotification({
        message: "User added successfully",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll(pageNo, searchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.message,
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("user"));
    const res = await userApi.getById(id);
    dispatch(setById(res.data.data));
    dispatch(setDialog());
    dispatch(setLoading("user"));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.message,
        type: "error",
      })
    );
    dispatch(setLoading("user"));
    throw new Error(err);
  }
};
const update = (id, data, pageNo, searchBody) => async (dispatch) => {
  try {
    await userApi.update(id, data);
    dispatch(
      showNotification({
        message: "User updated successfully",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll(pageNo, searchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.message,
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const handelResetPasswordByUser = (data) => async (dispatch) => {
  dispatch(setLoading("user"));
  try {
    await userApi.resetPasswordByUser(data);
    dispatch(setLoading("user"));
    dispatch(setResetPasswordDialog());
    dispatch(
      showNotification({
        message: "Password Changed Successfully",
        type: "success",
      })
    );
  } catch (err) {
    dispatch(setLoading("user"));
    dispatch(
      showNotification({
        message: err.response.data.message,
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const resetPassword = (id, data) => async (dispatch) => {
  dispatch(setLoading("user"));
  try {
    await userApi.resetPassword(id, data);
    dispatch(setLoading("user"));
    dispatch(setResetPasswordByAdminDialog(null));
    dispatch(
      showNotification({
        message: "User password changed successfully",
        type: "success",
      })
    );
  } catch (err) {
    dispatch(setLoading("user"));
    dispatch(
      showNotification({
        message: err.response.data.message,
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const userInfo = () => async (dispatch) => {
  try {
    dispatch(setUserInfoLoading());
    const res = await userApi.userInfo();
    dispatch(setUserInfo(res));
    dispatch(setUserInfoLoading());
  } catch (err) {
    dispatch(setUserInfoLoading());
    dispatch(
      showNotification({
        message: err.response.data.message,
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deleteById = (id, pageNo, searchBody) => async (dispatch) => {
  dispatch(setLoading("user"));
  try {
    await userApi.delete(id);
    dispatch(setLoading("user"));
    dispatch(getAll(pageNo, searchBody));
    dispatch(
      showNotification({
        message: "User Deleted Successfully",
        type: "success",
      })
    );
  } catch (err) {
    dispatch(setLoading("user"));
    dispatch(
      showNotification({
        message: err.response.data.message,
        type: "error",
      })
    );
    throw new Error(err);
  }
};
export const User = {
  getAll,
  create,
  getById,
  update,
  resetPassword,
  userInfo,
  deleteById,
  handelResetPasswordByUser,
};
